import { QuerySequelizeDTO } from "@/dto/query/QuerySequelize.dto";

class BuildingsQueriesVO {
  getManyBuildingName(buildingIds: string[], attributes = ["id", "name"]): QuerySequelizeDTO {
    return {
      attributes,
      where: {
        id: { $in: buildingIds },
      },
      include: [],
      offset: undefined,
      limit: undefined,
      order: undefined,
    };
  }

  getBuildingless() {
    return {
      attributes: [],
      where: {
        isBuildinglessUsersContainer: true,
        status: 1,
      },
      include: [],
      offset: 0,
      limit: 1,
      order: undefined,
    };
  }
}

const buildingsQueriesVO = new BuildingsQueriesVO();

export { buildingsQueriesVO, BuildingsQueriesVO };
