/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ProfileUsr, type Profile, type ProfileOrg, type Role } from "@amministro-io-packages/auth-interfaces";
import { reduceAND as and, reduceOR as or, negate } from "@/lib/functions";
import { capitalize } from "@/lib/strings";
import { AppInterface, bindingService, BindingService } from "@/services/bindings.service";
import { makeBinded } from "@/proxies";

export type StrategyToken = Exclude<
  Profile["interfaccia_amministroio"] | Profile["interfaccia_archivioplus"] | Profile["interfaccia_notificoonline"] | "ADMINISTRATION",
  null
>;

type extractFromRole<component extends "type" | "level", role = Role> = role extends `${infer type}_${infer level}`
  ? component extends "type"
    ? type
    : component extends "level"
    ? level
    : role
  : role;

/**
 * @alias AuthorizeService
 */
export class AuthorizeService {
  // TODO: aggiungere tipizzazione process.env e rimuovere i cast StrategyToken
  constructor(private readonly bindings: BindingService = bindingService) {}

  private _appAuthorizer: (profile: Profile) => boolean = negate;

  private hasApp(app: Exclude<StrategyToken, null>) {
    return (pr: Profile) => this.bindings.appsFromProfile(pr).find(({ app: prApp }) => prApp === app) !== undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isRole = (role: Role) => (pr: Profile) => pr.role === role;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isType = (roleType: extractFromRole<"type">) => (pr: Profile) => pr.role.split("_")[0] === roleType;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isLevel = (roleLevel: extractFromRole<"level">) => (pr: Profile) => pr.role.split("_")[1] === roleLevel;

  hasModule = (module: keyof Exclude<ProfileOrg["moduli"], undefined>) => (pr: ProfileOrg | ProfileUsr) => pr.moduli[module] === true;

  /**
   * Autorizza l'apertura di Alfred
   * Regola: Il profilo deve disporre dell'interfaccia
   * @param profile - il profilo richiesto
   * @returns true se ha accesso, false altrimenti
   */
  private _authorizeAlfred() {
    return and(this.hasApp(process.env.VUE_APP_ALFRED_ID as StrategyToken), this.hasModule("amministroio"));
  }

  /**
   * Autorizza l'apertura di Cruscotto
   * Regola: Il profilo deve disporre dell'interfaccia
   * @param profile - il profilo richiesto
   * @returns true se ha accesso, false altrimenti
   */
  private _authorizeCruscotto() {
    return and(this.hasApp(process.env.VUE_APP_CRUSCOTTO_ID as StrategyToken), this.hasModule("amministroio"));
  }

  /**
   * Autorizza l'apertura di Amministro io
   * Regola: Il profilo deve disporre dell'interfaccia
   * @param profile - il profilo richiesto
   * @returns true se ha accesso, false altrimenti
   */
  private _authorizeAmministroio() {
    return or(this.isType("spl"), and(this.hasApp(process.env.VUE_APP_AMMINISTROIO_ID as StrategyToken), this.hasModule("amministroio")));
  }

  /**
   * Autorizza l'apertura di InServizio
   * Regola: Il profilo deve disporre dell'interfaccia
   * @param profile - il profilo richiesto
   * @returns true se ha accesso, false altrimenti
   */
  private _authorizeInservizio() {
    return and(this.hasApp(process.env.VUE_APP_INSERVIZIO_ID as StrategyToken), this.hasModule("amministroio"));
  }

  /**
   * Autorizza l'apertura di CRM CityCloud
   * Regola: Il profilo deve disporre dell'interfaccia
   * @param profile - il profilo richiesto
   * @returns true se ha accesso, false altrimenti
   */
  private _authorizeCrmcitycloud() {
    return and(this.hasApp(process.env.VUE_APP_CRMCITYCLOUD_ID as StrategyToken), this.hasModule("amministroio"));
  }

  /**
   * Autorizza l'apertura di CRM Sfera
   * Regola: Il profilo deve disporre dell'interfaccia
   * @param profile - il profilo richiesto
   * @returns true se ha accesso, false altrimenti
   */
  private _authorizeCrmsfera() {
    return and(this.hasApp(process.env.VUE_APP_CRMSFERA_ID as StrategyToken), this.hasModule("amministroio"));
  }

  /**
   * Autorizza l'apertura di CRM Condominio
   * Regola: Il profilo deve disporre dell'interfaccia
   * @param profile - il profilo richiesto
   * @returns true se ha accesso, false altrimenti
   */
  private _authorizeCrmcondominio() {
    return and(this.hasApp(process.env.VUE_APP_CRMCONDOMINIO_ID as StrategyToken), this.hasModule("amministroio"));
  }

  /**
   * Autorizza l'apertura di CRM Valore24
   * Regola: Il profilo deve disporre dell'interfaccia
   * @param profile - il profilo richiesto
   * @returns true se ha accesso, false altrimenti
   */
  private _authorizeCrmvalore24() {
    return and(this.hasApp(process.env.VUE_APP_CRMVALORE24_ID as StrategyToken), this.hasModule("amministroio"));
  }

  /**
   * Autorizza l'apertura di Jarvis
   * Regola: Il profilo deve disporre dell'interfaccia
   * @param profile - il profilo richiesto
   * @returns true se ha accesso, false altrimenti
   */
  private _authorizeJarvis() {
    return and(this.hasApp(process.env.VUE_APP_JARVIS_ID as StrategyToken), this.hasModule("amministroio"));
  }

  /**
   * Autorizza l'apertura di Teapot
   * Regola: Il profilo deve disporre dell'interfaccia
   * @param profile - il profilo richiesto
   * @returns true se ha accesso, false altrimenti
   */
  private _authorizeTeapot() {
    return and(this.hasApp(process.env.VUE_APP_TEAPOT_ID as StrategyToken), this.hasModule("archivioplus"));
  }

  /**
   * Autorizza l'apertura di ArchivioPlus
   * Regola: Il profilo deve disporre dell'interfaccia
   * @param profile - il profilo richiesto
   * @returns true se ha accesso, false altrimenti
   */
  private _authorizeArchivioplus() {
    return or(this.isType("spl"), and(this.hasApp(process.env.VUE_APP_ARCHIVIOPLUS_ID as StrategyToken), this.hasModule("archivioplus")));
  }

  /**
   * Autorizza l'apertura di Administration
   * Regola: Il profilo deve disporre dell'interfaccia
   * @param profile - il profilo richiesto
   * @returns true se ha accesso, false altrimenti
   */
  private _authorizeAdministration() {
    return and(this.hasApp(process.env.VUE_APP_ADMINISTRATION_ID as StrategyToken));
  }

  /**
   * Autorizza l'apertura di NotificoOnline
   * Regola: Il profilo deve disporre dell'interfaccia
   * @param profile - il profilo richiesto
   * @returns true se ha accesso, false altrimenti
   */
  private _authorizeNotificoonline() {
    return and(this.hasApp(process.env.VUE_APP_NOTIFICOONLINE_ID as StrategyToken), this.hasModule("notificoonline"), this.isType("org"));
  }

  /**
   * Autorizza l'apertura di Doorbell
   * Regola: Il profilo deve disporre dell'interfaccia
   * @param profile - il profilo richiesto
   * @returns true se ha accesso, false altrimenti
   */
  private _authorizeDoorbell() {
    return and(this.hasApp(process.env.VUE_APP_DOORBELL_ID as StrategyToken), this.hasModule("notificoonline"), this.isType("org"));
  }

  /**
   * TODO: documentazione
   * @param app
   * @returns
   */

  setStrategy(app: StrategyToken) {
    if (app !== null) {
      this._appAuthorizer = this[`_authorize${capitalize(app)}` as `_authorize${Capitalize<Lowercase<Exclude<StrategyToken, null>>>}`]();
    }

    return this;
  }

  /**
   * TODO: documentazione
   * @param profile
   * @returns
   */
  authorizeProfile(profile: Profile | null) {
    const authorized = profile !== null ? this._appAuthorizer(profile) : false;
    return authorized;
  }

  /**
   * TODO: documentazione
   * @param app
   * @param profiles
   * @returns
   */
  authorizedProfiles(app: AppInterface, profiles: Profile[]): Profile[] {
    if (app) {
      this.setStrategy(app);
      return profiles.filter((pr) => this.authorizeProfile(pr));
    } else {
      return profiles;
    }
  }
}

export const authorizeService = makeBinded(AuthorizeService);
